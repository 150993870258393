<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="12">
                    <el-form size="medium" :label-width="this.env.label_width">
                        <el-form-item label="人数设置">
                            <div v-for="(item,index) in qty" :key="index">
                                1v
                                <el-input-number size="small" :step-strictly="true" v-model="qty[index]" :min="1"
                                                 :max="10"></el-input-number>
                                <el-button type="text" class="del_btn" @click="del_qty(index)">删除</el-button>
                            </div>
                            <el-button type="text" @click="add_qty">添加</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <el-form size="medium" :label-width="this.env.label_width">
                        <el-form-item label="课程数设置">
                            <div v-for="(item,index) in lesson_count" :key="index">
                                <el-input-number size="small" :step-strictly="true" v-model="lesson_count[index]"
                                                 :min="1"
                                                 :max="100"></el-input-number>
                                节课
                                <el-button type="text" class="del_btn" @click="del_lesson_count(index)">删除</el-button>
                            </div>
                            <el-button type="text" @click="add_lesson_count">添加</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>

                <el-col :span="16">
                    <el-form size="medium" :label-width="this.env.label_width">
                        <el-form-item>
                            <el-button v-if="this.Tool.is_auth('lessonschedule.ptsku.issave')" size="medium"
                                       type="primary"
                                       @click="save">保存
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            page_name: '私教规格',
            qty: [],            //私教设置
            lesson_count: [],           //课程设置
        }
    },
    // 创建
    created() {
    },
    // 安装
    mounted() {
        this.getinfo();
    },
    methods: {
        // 获取私教详情
        getinfo() {
            let postdata = {
                api_name: 'lessonschedule.ptsku.getinfo',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.qty = json.data.qty
                    this.lesson_count = json.data.lesson_count
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        //人数设置
        //添加
        add_qty() {
            this.qty.push(1)
        },
        //删除
        del_qty(index) {
            this.qty.splice(index, 1)
        },

        //课程设置
        //添加
        add_lesson_count() {
            this.lesson_count.push(1)
        },
        //删除
        del_lesson_count(index) {
            this.lesson_count.splice(index, 1)
        },


        // 保存私教规格
        save() {
            let postdata = {
                api_name: "lessonschedule.ptsku.issave",
                token: this.Tool.get_l_cache('token'),
                qty: this.qty,
                lesson_count: this.lesson_count,
            }
            this.Tool.post_data('merchant', postdata, (json) => {
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                    this.getinfo()
                } else {
                    this.$message.error(json.message);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.del_btn {
    margin-left: 20px;
    color: #f56c6c;
}

</style>
